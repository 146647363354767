import Role from "./Role.vue"
import RoleCreate from "./Create.vue"
import RoleEdit from "./Edit.vue"
import RoleShow from "./Show.vue"


export default [
    {
        path: "/role",
        component: Role,
        name: "Role"
    },
    {
        path: "/role/create",
        component: RoleCreate,
        name: "RoleCreate"
    },
    {
        path: "/role/edit/:id",
        component: RoleEdit,
        name: "RoleEdit"
    },
    {
        path: "/role/show/:id",
        component: RoleShow,
        name: "RoleShow"
    },
]